import { datadogRum } from '@datadog/browser-rum'
import App from 'next/app'
import pack from '../package.json'

datadogRum.init({
    applicationId: '7d061b9d-183e-4213-a62d-24614fc29c77',
    clientToken: 'pub37c390bdbd4e6747620bd8b8065bc600',
    site: 'datadoghq.com',
    service: 'feed-reader',
    env: 'dev',
    // Specify a version number to identify the deployed version of your application in Datadog
    version: pack.version,
    sampleRate: 100,
    trackInteractions: true,

    //https://docs.datadoghq.com/real_user_monitoring/connect_rum_and_traces?tab=browserrum
    allowedTracingOrigins: [
        'http://localhost:3000',
        /https:\/\/.*\.my-api-domain\.com/,
    ],
})

export default App
